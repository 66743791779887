export const accountSessionKey = "_vad";
export const getUserAccount = () => {
  const session = localStorage.getItem(accountSessionKey);
  if (session=== null) {
    return null;
  }
  var a = JSON.parse(decodeURIComponent(escape(atob(session))))
  if (a === null || a.access_token === undefined) {
    return null;
  }
  return a;
}


export function updateAccount(account) {
  var encodedAccount = btoa(unescape(encodeURIComponent(JSON.stringify(account))));
  localStorage.setItem(accountSessionKey, encodedAccount)
}

export function Logout() {
  localStorage.removeItem(accountSessionKey)
}